<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";

  import { useCookies } from "vue3-cookies";
  import { useStore } from 'vuex'
  import { mapActions, mapGetters} from "vuex";
  import { computed } from 'vue'
 
  import "@vueform/multiselect/themes/default.css";

  import Swal from "sweetalert2";
  import axios from 'axios';
  import serverConfig from '@/helpers/config';
  //import Select from '../forms/select.vue';

  export default {
    name: "detailFile",
    emits:['getParent','getParentProject'],
    props: [
    'id',
    'projectID',
    'storageType',
    'type',
    'url',
    'data',
    'getTag',
    'detailProject',
    ],
    setup() {
        const { cookies } = useCookies();
        const store = useStore();
       
        let tk = 'Bearer '+localStorage.getItem('tk')

        let gettersSettingRole = computed(() => store.getters["settings/getSettingsRole"])
        let gettersSettingArea = computed(() => store.getters["settings/getSettingsArea"])
        
        return { cookies, tk, gettersSettingRole, gettersSettingArea, v$: useVuelidate()};
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Dettaglio file",
        dataEdit:{},
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        path:'',
        download:'',
        fileTypeSettings:[],
        importList:false,
        docTypeRequired:[],
      };
    },
    validations: {
      inviteNUser: {
        email: {
          required: helpers.withMessage("Email obbligatoria", required),
          email: helpers.withMessage("Per favore, inserisci una email valida", email),
        },
        status: {
          required: helpers.withMessage("Stato obbligatorio", required),
        },
        role: {
          required: helpers.withMessage("Ruolo obbligatorio", required),
        },
      },
    },
    computed: {
      ...mapGetters('settings', ['getSettingsParams']),
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    methods: {
      ...mapActions('auth', ['refreshToken','clearSessionStorage']),
      ...mapActions('settings', ['getSettings_Params',]),
      len(l){
        if(l){
          return l.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
      getPar(){
        this.getSettings_Params()
      },
      fileTypeCheck(file){
        if (file){
          if (file.split('/')[0] == 'image'){
            return 'image'
          } else {
            return file
          }
        
        }
      },

      getFileType(tag) {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/tag/${tag}`,  
          {headers: { authorization:this.tk }} 
        ).then((response)=>{
          if(response){
            this.fileTypeSettings = response.data
           
          }
        })
      },
      getItem() {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}storage/${this.id}`, 
        {headers: { authorization:this.tk},
        onUploadProgress: progressEvent => {
          const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          console.log('get storage....')
          console.log(progress)
        },
        progress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                  console.log('progress...')
                      console.log(progressEvent.loaded + ' ' + progressEvent.total);
                //this.updateProgressBarValue(progressEvent);
                         }
                 }
        }, )
        .then((response) => {
         
            this.dataEdit = response.data
            if (this.dataEdit.path) {
              this.getPath(this.dataEdit.path)
            }
            this.getFileType('filetype')
            this.getDocs()
        })
      },
        getPath(data) {
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}storage/f/db/to/gcloud/get-signed-url`, 
              { 
                params: { 
                  filepath: data,
                }, 
                headers: { 'authorization':this.tk}
              }
            ).then(response=>{
                this.path  = response.data  
            })
        },
        importData() {
          this.axiosInterceptor()
          let urlimport = false

          if (this.storageType=='pricelist-import') {
            urlimport = 'excel'
          }
          if (this.storageType=='salesroute-import') {
            urlimport = 'excel-sr'
          }
          if(urlimport){
            axios.post(`${this.UrlServer}storage/import/${urlimport}`,
              { 
                url: this.data.path,
              }, 
              { 
                headers: { 'authorization':this.tk}
              }
            ).then(response=>{
              
              this.importList = response.data
            })
          }

        },
      updatedata() {
        
        let data = this.dataEdit 
        data.tag_name = this.dataEdit.tag_name
        delete data._id
        delete data.id
        delete data.projectID
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}storage/${this.id}`, data, 
         {headers: { authorization:this.tk},        progress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                  console.log('progress...')
                      console.log(progressEvent.loaded + ' ' + progressEvent.total);
                //this.updateProgressBarValue(progressEvent);
                         }
                 }} )
          .then((response) => {
            document.getElementById("edtclosemodal").click();
            if(response.data){
              //Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
              if (this.type=='project'){
               this.updateProject()

              }
             this.$emit('getParentProject')
              
            }

          })
      },
      updateProject(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}projects/documents/by-project/${this.projectID}`, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data){
              this.$emit('getParentProject')
            }
          })
      },
      getOptions(objList){
        if (objList) {
          let l = [{
              value:'',
              label:'Seleziona tipologia documento'
            }
          ]
          for (let item of objList)
          {
            l.push({
              value:item.value,
              label:item.name,
            })
          }
          return l
        } else {
          return []
        }

      },
      getDocs() {
        if (this.detailProject) {
          let tipologia = 'persona_fisica' 
          if (this.detailProject.customer.TipoSoggetto != '3') {
            tipologia = 'persona_giuridica'
          }
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}settings/all/documents/list`, 
          { params:{
              type: "tag_document",
              project_type: this.detailProject.project_type,
              ref: tipologia,
            },
            headers: { authorization:this.tk}
          }).then((response) => {

            if (response.data) {
              this.docTypeRequired = response.data
            }        
          })
        }


      },     
    },
    mounted() {
     
    },
    components: {
     
    },
  };
</script>

<template>
<!-- MODAL edit anagrafica utente -->
  <a :href="'#editmodal_'+id" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="getItem()">
    <i class="ri-pencil-fill fs-16"></i>
  </a>
    
  <div class="modal modal-xl  fade" data-bs-backdrop="static"  :id="'editmodal_'+id" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-scrollable text-left" data-backdrop="static">
                <div class="modal-content">
                  <div class="modal-header  bg-dark p-3 pl-5 pr-5">
                    <h5 id="editModalLabel">
                      Modifica dati file
                    </h5>
                    <button type="button" class="btn-clos" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input type="text" id="edtidfield1" class="form-control" placeholder="ID" readonly />
                       
                      </div>
                      <div class="row mb-30">
                        <div class="col-md-12 col-lg-12 mb-30">
                          <div class="mb-3">
                            <label for="name" class="form-label">Nome originale del file</label>
                            <h3>{{ dataEdit.originalname }}</h3>
                            
                          </div>
                        </div>
                      </div>
                      <div class="row mt-20">
                        <div class="col-md-12 col-lg-4">
                          <div class="mb-3">
                            <label for="tag_name" class="form-label">Tipologia di file</label>
                            <select class="form-select"  placeholder="Seleziona Tipologia di file" data-trigger id="contractInput" v-model="dataEdit.tag_name" >
                              <option value="">Seleziona Tipologia di file</option>
                              <option v-for="item in fileTypeSettings" :key="item._id" :value="item.value" > {{ item.name }}</option>
                            </select>   
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="mb-3">
                              <label for="tag_document" class="form-label">Tipo di documento obbligatorio</label>
                              <select class="form-select"  placeholder="Seleziona Tipologia di documento" data-trigger id="tagDocInput" v-model="dataEdit.tag_document"  >
                                <option value="">Seleziona Tipologia</option>
                                <option v-for="item in docTypeRequired" :key="item._id" :value="item.value" > {{ item.label }}</option>
                              </select> 
                              
                            </div>
                        </div>

                      </div>

                      <div class="row mt-20">
                        <div class="col-md-12 col-lg-12">
                            <div class="mb-3">
                                <label for="ragSoc" class="form-label">Denominazione file</label>
                                <input type="text" id="ragSoc" v-model="dataEdit.name" class="form-control" placeholder="Associa un nome" required />
                            </div>                        
                        </div>
                      </div>
                        <div class="row mt-20">
                          <div class="col-md-12 col-lg-12">
                              <div class="mb-3">
                                  <label for="note" class="form-label">Note</label>
                                  <textarea class="form-control" id="note" rows="3" placeholder="Inserisci eventuali note" v-model="dataEdit.note" ></textarea>                       
                              </div>                        
                          </div>
                        </div>
                        <div class="row mt-20">
                          <div class="col-md-12 col-lg-12">
                              <div class="mb-3">
                                  <label for="keywords-field" class="form-label">Parole chiave</label>
                                  <textarea class="form-control" id="keywords" rows="3" placeholder="Inserisci eventuali parole chiave" v-model="dataEdit.keywords"></textarea>                       

                              </div>
                          </div>
                        </div>
                        <div class="row mt-20">
                        <div class="col-md-12 col-lg-12" v-if="dataEdit.mimetype=='application/vnd.ms-excel' || dataEdit.mimetype=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                            <div class="mb-3">
                                <label for="keywords-field" class="form-label">Importazione dati</label>
                                <br>
                                <button class="btn btn-info" type="button" @click="importData">Importa</button>
                            </div>
                           
                        </div>
                     
                       </div>
                       
                       <div class="row">
                        <div class="col-md-12 col-lg-12" v-if="dataEdit.mimetype=='application/vnd.ms-excel' || dataEdit.mimetype=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                          <template v-if="importList">
                            <br>
                            <template v-if="importList.message == 'Error'">
                              <label class="alert alert-danger">Ops, c'è stato un errore d'importazione</label>
                            </template>
                            <template v-else>
                              <label class="alert alert-success"><strong>Importazione avvenuta con successo!</strong></label>
                              <br>
                              <p>Prodotti aggiornati: <strong>{{ len(importList.productListUpdated) }}</strong></p>
                              <p>Prodotti creati: <strong>{{len(importList.productListCreaded)}}</strong></p>
                              <template v-if="storageType=='pricelist-import'">
                                <p>Listini aggiornati: <strong>{{len(importList.priceListUpdated)}}</strong></p>
                                <p>Listini creati: <strong>{{len(importList.priceListCreated)}}</strong></p>
                              </template>
                              <template v-if="storageType=='salesroute-import'">
                                <p>Canali aggiornati: <strong>{{len(importList.salesRouteUpdated)}}</strong></p>
                                <p>Canali creati: <strong>{{len(importList.salesRouteCreated)}}</strong></p>
                              </template>
                            
                            </template>
                          </template>
                        </div>
                       </div>
                       <div class="row mt-20">
                          <div class="col-md-12 col-lg-12 text-center">
                              <div class="mb-3">
                                <b-button type="button" variant="success" size="lg" @click="updatedata">
                                  <i class="ri-save-line align-bottom me-1"></i>
                                    Aggiorna
                                </b-button>
                              </div>
                          </div>
                        </div>

                      </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        
                        <button type="button" id="edtclosemodal" class="btn btn-light" data-bs-dismiss="modal">
                          Chiudi
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
<!-- / MODAL edit anagrafica utente -->
</template>